<template>
	<div>
		<!-- <el-card shadow="never" style="margin-bottom: 10px;">
			<el-descriptions :title="''">
				<el-descriptions-item :label="$t('i18nn_b0c50b6ff9674173')">Fedex</el-descriptions-item>
				<el-descriptions-item :label="$t('i18nn_581bc71c027386cb')">{{trackingNumber}}</el-descriptions-item>
			</el-descriptions>
		</el-card> -->
		
		<div>
			<!-- {{tableData.warnings}} -->
			<el-card shadow="never" style="" v-if="tableData.warnings && tableData.warnings.length>0">
				<!-- <div slot="header" class="">
					<h3>{{$t('i18nn_d88844033912c1fc')}}</h3>
				</div> -->
				<div>
					<el-descriptions size="small" :title="$t('i18nn_d88844033912c1fc')" v-for="(item,index) in tableData.warnings" :key="index">
						<el-descriptions-item :label="$t('i18nn_2678054e53eeefdd')">
							{{item.code}}
						</el-descriptions-item>
						<el-descriptions-item :label="$t('i18nn_d88844033912c1fc')">
							{{item.message}}
						</el-descriptions-item>
					</el-descriptions>
				</div>
			</el-card>
		</div>


		<div style="display: flex;"  v-for="(itemPack,indexPack) in tableData.package" :key="indexPack">
			<div style="flex:1;">
				<el-card shadow="never" style="margin-right: 10px;">
					<div slot="header" class="">
						<h3>{{$t('i18nn_094903a0e1dd5849')}}</h3>
					</div>
					<div>
						<el-timeline>
							<el-timeline-item v-for="(item,index) in itemPack.activity" :key="index"
								:timestamp="formatterDate(item.date,item.time)" placement="top">
								<el-card shadow="never" :style="{color:0==index?'#e6a23c':''}">
									<p>
										<strong>{{item.status.description}}</strong>
									</p>
									<p v-if="item.location && item.location.address">
										<span
											v-if="item.location.address.streetLines">{{item.location.address.streetLines.join(",")}}</span>
											
										<span>{{item.location.address.city}}</span>,
										<span>{{item.location.address.stateProvince}}</span>,
										<span>{{item.location.address.country}}</span>,
									</p>
									<!-- <p>
										<el-tag type="info">{{item.derivedStatus}}</el-tag>
										<el-tag type="info">{{item.eventType}}</el-tag>
										<el-tag type="info">{{item.locationType}}</el-tag>
									</p> -->
									<!-- <p v-if="item.exceptionCode||item.exceptionDescription">
										<el-tag type="warning">
											{{item.exceptionCode}}
										</el-tag>
										<el-tag type="warning">
											{{item.exceptionDescription}}
										</el-tag>
									</p> -->
								</el-card>
							</el-timeline-item>
						</el-timeline>
					</div>
				</el-card>
			</div>
			<div style="flex:1;">
				<el-card shadow="never" style="">
					<div slot="header" class="">
						<h3>{{$t('i18nn_032abee95f72da18')}}</h3>
					</div>
					<el-descriptions size="small" :title="$t('i18nn_b98aefdd52a12eee')" :column="2">
						<el-descriptions-item :label="$t('i18nn_f9db93b87e08763b')">{{itemPack.packageCount}}</el-descriptions-item>
					</el-descriptions>
					
					<el-descriptions size="small" :title="$t('i18nn_06ef5de26edd9e88')" :column="2">
						<el-descriptions-item :label="$t('i18nn_d721bca263c00924')" v-for="(item,index) in itemPack.deliveryDate" :key="index">
							{{item.date}}<el-tag type="info">{{item.type}}</el-tag>
						</el-descriptions-item>
						<el-descriptions-item :label="$t('i18nn_184333c81babf2f1')" v-if="itemPack.deliveryTime">
							{{itemPack.deliveryTime.type}}
						</el-descriptions-item>
					</el-descriptions>
					<!-- <el-descriptions size="small" :title="$t('i18nn_f2896032570c4d41')">
						<el-descriptions-item :label="$t('i18nn_eca5c29ad1a845c1')" v-if="tableData.lastUpdatedDestinationAddress">
							{{tableData.lastUpdatedDestinationAddress.city}},
							{{tableData.lastUpdatedDestinationAddress.stateOrProvinceCode}},
							{{tableData.lastUpdatedDestinationAddress.countryName}}
						</el-descriptions-item>
						<el-descriptions-item :label="$t('i18nn_4851293ecdd539ed')" v-if="tableData.recipientInformation">
							{{tableData.recipientInformation.address.city}},
							{{tableData.recipientInformation.address.stateOrProvinceCode}},
							{{tableData.recipientInformation.address.countryName}}
						</el-descriptions-item>
						<el-descriptions-item :label="$t('i18nn_93ba6023a4128d75')"
							v-if="tableData.shipperInformation && tableData.shipperInformation.address">
							{{tableData.shipperInformation.address.city}},
							{{tableData.shipperInformation.address.stateOrProvinceCode}},
							{{tableData.shipperInformation.address.countryName}}
						</el-descriptions-item>
					</el-descriptions>
					<el-descriptions size="small" :title="'服务信息(serviceDetail)'" v-if="tableData.serviceDetail">
						<el-descriptions-item :label="$t('i18nn_ab6ea90b9164b20a')">{{tableData.serviceDetail.description}}
						</el-descriptions-item>
						<el-descriptions-item :label="$t('i18nn_847f063538ae47c2')">{{tableData.serviceDetail.shortDescription}}
						</el-descriptions-item>
						<el-descriptions-item :label="$t('i18nn_184333c81babf2f1')">{{tableData.serviceDetail.type}}</el-descriptions-item>
					</el-descriptions>
					<el-descriptions size="small" :title="'服务命令信息(serviceCommitMessage)'" :column="2"
						v-if="tableData.serviceCommitMessage">
						<el-descriptions-item :label="$t('i18nn_8e2be23dd3b81a31')">{{tableData.serviceCommitMessage.message}}
						</el-descriptions-item>
						<el-descriptions-item :label="$t('i18nn_184333c81babf2f1')">{{tableData.serviceCommitMessage.type}}
						</el-descriptions-item>
					</el-descriptions>
					<el-descriptions size="small" :title="'包裹信息(packageDetails)'" :column="2"
						v-if="tableData.packageDetails">
						<el-descriptions-item :label="$t('i18nn_ceffcc237c2608b9')">{{tableData.packageDetails.count}}</el-descriptions-item>
						<el-descriptions-item :label="$t('7b1c2b1adc920d9c')">{{tableData.packageDetails.sequenceNumber}}
						</el-descriptions-item>
						<el-descriptions-item :label="$t('i18nn_184333c81babf2f1')">
							{{tableData.packageDetails.physicalPackagingType}}
						</el-descriptions-item>
						<el-descriptions-item :label="$t('i18nn_ab6ea90b9164b20a')" v-if="tableData.packageDetails.packagingDescription">
							{{tableData.packageDetails.packagingDescription.description}}
								,{{tableData.packageDetails.packagingDescription.type}}
						
						</el-descriptions-item>
						<el-descriptions-item :label="$t('i18nn_96f1a4b17e75892d')" v-if="tableData.packageDetails.weightAndDimensions">
							<ul>
								<li v-for="(item,index) in tableData.packageDetails.weightAndDimensions.weight"
									:key="index">
									{{item.value}}&nbsp;{{item.unit}}
								</li>
							</ul>
						</el-descriptions-item>
					</el-descriptions>
					<el-descriptions size="small" :title="$t('i18nn_50a95a5f20c112cf')" :column="1"
						v-if="tableData.additionalTrackingInfo && tableData.additionalTrackingInfo.packageIdentifiers">
						<el-descriptions-item :label="item.type" v-for="(item,index) in tableData.additionalTrackingInfo.packageIdentifiers" :key="index">
							{{item.values.join(",")}}
						</el-descriptions-item>


					</el-descriptions>
					<el-descriptions size="small" :title="'最新状态详情(latestStatusDetail)'" :column="2"
						v-if="tableData.latestStatusDetail">
						<el-descriptions-item :label="$t('i18nn_ab6ea90b9164b20a')">{{tableData.latestStatusDetail.description}}
						</el-descriptions-item>
						<el-descriptions-item :label="$t('i18nn_6cdece641436d7ab')">{{tableData.latestStatusDetail.statusByLocale}}
						</el-descriptions-item>
						<el-descriptions-item :label="$t('i18nn_5360545085fcff1f')">
							<ul>
								<li v-for="(item,index) in tableData.latestStatusDetail.ancillaryDetails">
									<p>{{item.reasonDescription}},{{item.reason}}</p>
									<p>{{item.actionDescription}},{{item.action}}</p>
								</li>
							</ul>
						</el-descriptions-item>
					</el-descriptions> -->
				</el-card>
			</div>
		</div>


	</div>
</template>

<script>
	export default {
		// name: 'HomeConfFrame',
		props: {
			openTime: {
				// default: function() {
				//   return '';
				// },
				// type: String
			},
			itemData: {}
		},
		components: {

		},
		data() {
			return {
				trackingNumber: "",
				//表格数据
				tableData: {},
			};
		},
		watch: {
			openTime: function(newVal, oldVal) {
				console.log('openTime');
				this.initData();
			}
		},

		activated() {
			// console.log(' activated');
		},
		//创建时
		created() {
			// console.log(' created');
		},
		//编译挂载前
		mounted() {
			this.initData();
		},
		methods: {
			initData() {
				this.trackingNumber = this.itemData.inquiryNumber;
				this.tableData = this.itemData;
				// if (this.itemData && this.itemData.package && this.itemData.package[0]) {
				// 	this.tableData = this.itemData.package[0];
				// } else if(this.itemData){
				// 	this.tableData = this.itemData;
				// }

			},
			formatterDate(DateStr,timeStr) {
				// if (DateStr) {
				// 	return this.$Utils.fomatterDate_YYYYMMddhms(DateStr);
				// } else {
				// 	return "";
				// }
				if(DateStr && timeStr && DateStr.length>7 && timeStr.length>5){
					return (DateStr.substring(0,4)+"-"+DateStr.substring(4,6)+"-"+DateStr.substring(6,8))
					+" "
					+(timeStr.substring(0,2)+":"+timeStr.substring(2,4)+":"+timeStr.substring(4,6));
					// return DateStr+" "
					// + timeStr.substring(0,2)+":"+timeStr.substring(2,4)+":"+timeStr.substring(4,6);
				} else {
					return DateStr+" "+timeStr;
				}
			},

		}
	};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="less">

</style>
